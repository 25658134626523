import VueRouter from "vue-router"
import Vue from "vue"
import activityShare from "@/views/activityShare"
import businessCard from "@/views/businessCard"
import newBusinessCard from "@/views/newBusinessCard"
import qrcode from "@/views/qrcode"


Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/activityShare'
    },
    {
        path: '/activityShare',
        name: '活动详情',
        meta: {},
        component: activityShare,
    },
    {
        path: '/businessCard',
        name: '名片',
        meta: {},
        component: businessCard,
    },
    {
        path: '/newBusinessCard',
        name: '新名片',
        meta: {},
        component: newBusinessCard,
    },
    // {
    //     path: '/qrcode',
    //     name: '活动二维码',
    //     meta: {},
    //     component: qrcode,
    // },
]

const router = new VueRouter({
    routes
})

export default router