import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import { NavBar, CountDown, Empty, Loading } from 'vant';
import 'amfe-flexible'

Vue.use(NavBar).use(CountDown).use(Empty).use(Loading)
Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-app'];

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')