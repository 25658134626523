<template>
  <div class="contain">
    <!-- <van-nav-bar title="活动详情" /> -->
    <div v-if="!loading">
      <div v-if="detail">
        <div
          class="top rel"
          :style="{ backgroundImage: `url(${detail.info.poster})` }"
        ></div>
        <div class="bottom flex">
          <div v-if="remainTime > 0">
            <div class="tip">{{ detail.address }}</div>
            <div class="tip">活动结束倒计时：</div>
            <van-count-down :time="remainTime">
              <template #default="timeData">
                <span class="block">{{ timeData.days }}</span>
                <span class="colon">天</span>
                <span class="block">{{ timeData.hours }}</span>
                <span class="colon">时</span>
                <span class="block">{{ timeData.minutes }}</span>
                <span class="colon">分</span>
                <span class="block">{{ timeData.seconds }}</span>
                <span class="colon">秒</span>
              </template>
            </van-count-down>
          </div>
          <div v-else class="tip">活动已结束</div>
          <!-- <div v-if="isWeixin" id="wola" class="mobile-page-download"> -->
          <wx-open-launch-app
            v-if="isWeixin"
            id="launch-btn"
            appid="wxc559609bd03742f6"
            :extinfo="`/pageActivityDetail?id=${this.id}&needHome=1`"
            @launch="launch"
            @error="launchFail"
          >
            <script type="text/wxtag-template">
              <style>.btn {
                width: 335px;
              height: 45px;
              line-height: 45px;
              text-align: center;
              background: #0086d1;
              border-radius: 68px;
              margin: 31px auto 0;
              font-size: 15px;
              font-weight: 400;
              color: #ffffff;
              border: 1px solid #0086d1;
               }</style>
              <button  class="btn">查看活动详情</button>
            </script>
          </wx-open-launch-app>
          <!-- </div> -->
          <div v-else class="btn" @click="toAppDetail">查看活动详情</div>
        </div>
      </div>
      <van-empty v-else description="活动不存在或已删除" />
    </div>
    <div v-else>
      <van-loading color="#1989fa" size="24px" vertical>加载中...</van-loading>
    </div>
  </div>
</template>
<script>
import { weixinUrl,life3URL } from "../config";
import { getWeixin } from "../api";
import request from "axios";
import { formatDate } from "@/utils";
export default {
  data() {
    return {
      id: this.$route.query.id,
      loading: true,
      detail: "",
      date: "",
      remainTime: "",
      isWeixin: false,
    };
  },
  methods: {
    launch() {
      // console.log(12321321);
    },
    launchFail(e) {
      alert(`跳转失败:${JSON.stringify(e)}`);
      // alert(e.errMsg);
    },
    getDetail() {
      if (this.isWeixin) {
        getWeixin({
          url: weixinUrl,
          js_api_list: ["onMenuShareAppMessage"],
        }).then((res) => {
          let data = res.data;
          let { appId, timestamp, nonceStr, signature, jsApiList } = data;
          wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印
            appId, // 必填，公众号的唯一标识
            timestamp, // 必填，生成签名的时间戳
            nonceStr, // 必填，生成签名的随机串
            signature, // 必填，签名
            jsApiList, // 必填，需要使用的 JS 接口列表
            openTagList: ["wx-open-launch-app"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
          });
          wx.ready(function () {
            console.log("config信息验证成功");
          });
          wx.error(function (err) {
            console.log(err);
            // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名
          });
        });
      }
      if (!this.id) {
        this.loading = false;
        return;
      }
      request({
        url: `${life3URL}v1/api/activity/${this.id}`,
        // url: `//test.3-life-api.zjxnzx.cn/v1/api/activity/${this.id}`,
      }).then((res) => {
        this.detail = res.data.data;
        this.date = formatDate(this.detail.end_time);
        let now = new Date().getTime();
        this.remainTime = this.detail.begin_time * 1000 - now;
        this.loading = false;
        // this.remainTime = 1662365312 * 1000 - now
      });
    },
    toAppDetail() {
      let appUrl = `orgapp://pageActivityDetail?id=${this.id}`;
      let iphoneDownUrl = `https://apps.apple.com/cn/app/%E5%85%B4%E5%92%A8%E4%BA%91/id1642661159`;
      let androidDownUrl = `https://www.pgyer.com/pros`;
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; // Android系统或者uc浏览器
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // iOS系统
      if (this.isWeixin) {
        alert("请通过网页打开");
        return;
      }
      // 如果为Android系统
      if (isAndroid) {
        window.location.href = appUrl; // 直接跳转到App的链接，可以是 scheme 也可以是 App link 链接
        window.setTimeout(function () {
          // alert("若未跳转，请复制链接到浏览器打开");
          window.location.href = androidDownUrl; // 3s后如果不能跳转到 App，则跳转到 App 的下载地址，一般是应用宝的对应的下载地址
        }, 3000);
      }
      // ios设备：原理：判断是否认识这个协议，认识则直接跳转，不认识就在这里下载appios();
      if (isiOS) {
        // console.log("isiOS")
        let startIndex = u.indexOf("iPhone OS") + 9;
        let endIndex = u.indexOf("like Mac OS") - 1;
        let num = +u.slice(startIndex, endIndex).split("_")[0]; // 计算版本号的前面数值

        if (num < 9) {
          window.location.href = appUrl; // URL scheme 链接
        } else {
          window.location.href = appUrl; // universal link 链接
        }
        window.setTimeout(function () {
          // alert("若未跳转，请复制链接到浏览器打开");
          window.location.href = iphoneDownUrl; // 3s后如果不能跳转到 App，则跳转到 App 的下载地址，一般是应用宝的对应的下载地址
        }, 3000);
      }
    },
    judgeIsWeixin() {
      let ua = navigator.userAgent.toLowerCase();
      this.isWeixin = ua.indexOf("micromessenger") != -1;
    },
  },
  created() {
    // console.log(111);
    this.judgeIsWeixin();
    this.getDetail();
  },
};
</script>
<style scoped lang="less">
.contain {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: #f3fbff;
  #launch-btn {
    // width: 100%;
    // display: flex;
    // align-items: center;
  }
  .btn {
    width: 200px;
  }

  .top {
    width: 100%;
    height: 521px;
    background-size: cover;
    background-position: center;

    .logo {
      left: 20px;
      top: 25px;
      color: #ffffff;
      align-items: center;
      font-size: 19px;

      img {
        width: 40px;
        height: 40px;
        background: #ffffff;
        border-radius: 50%;
        border: 1px solid #707070;
        margin-right: 9px;
      }
    }

    .date {
      padding: 6px;
      right: 20px;
      top: 20px;
      width: 59px;
      height: 68px;
      opacity: 1;
      border: 1px solid #ffffff;
      color: #ffffff;

      .ym {
        font-size: 13px;
        border-bottom: 1px solid #ffffff;
        padding: 2px 0;
      }

      .d {
        text-align: center;
        font-size: 30px;
        line-height: 40px;
      }
    }

    .detail {
      bottom: 40px;
      left: 20px;
      width: 336px;
      min-height: 162px;
      background: rgba(255, 255, 255, 0.77);
      border-radius: 14px;
      padding: 12px 20px;

      .title {
        font-size: 21px;
        font-weight: bold;
        color: #0086d1;
        margin-bottom: 10px;
      }

      .info {
        font-size: 16px;
        font-weight: 400;
        color: #3e3e3e;
        line-height: 30px;
      }
    }
  }

  .bottom {
    flex-direction: column;
    align-items: center;
    padding: 15px 0 30px;

    .tip {
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #3e3e3e;
      margin-bottom: 15px;
    }

    .btn {
      width: 335px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      background: #0086d1;
      border-radius: 68px;
      margin-top: 31px;
      font-size: 15px;
      font-weight: 400;
      color: #ffffff;
      
    }
  }

  .van-loading,
  .van-empty {
    margin-top: 40vh;
    transform: translateY(-50%);
  }
}
</style>
<style>
.colon {
  display: inline-block;
  margin: 0 4px;
  color: #868686;
}

.block {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  text-align: center;
  width: 21px;
  height: 21px;
  background: #0086d1;
  border-radius: 5px;
}
</style>
