<template>
  <div>
    <canvas ref="qrcodeCanvas"> </canvas>
  </div>
</template>
<script>
// 引用
import QRcode from "qrcode";
export default {
  data() {
    return {
      detail: {
        id: 341,
      },
    };
  },
  mounted() {
    // 这个库传入两个参数
    // 1. canvas dom
    // 2. 字符串
    let id = this.$route.query.id;
    QRcode.toCanvas(this.$refs.qrcodeCanvas, JSON.stringify({ id }), {
      width: "100%", //自定义宽度
      color: {
        dark: "#00f", //自定义码的颜色
        light: "#fbb", //自定义背景颜色
      },
    });
  },
};
</script>
