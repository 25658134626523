import { render, staticRenderFns } from "./businessCard.vue?vue&type=template&id=bfd94876&scoped=true"
import script from "./businessCard.vue?vue&type=script&lang=js"
export * from "./businessCard.vue?vue&type=script&lang=js"
import style0 from "./businessCard.vue?vue&type=style&index=0&id=bfd94876&prod&scoped=true&lang=less"
import style1 from "./businessCard.vue?vue&type=style&index=1&id=bfd94876&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfd94876",
  null
  
)

export default component.exports