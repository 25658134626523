<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {

    }
  },
  methods: {

  },

}
</script>

<style>
*{
  box-sizing: border-box;
}
.flex {
  display: flex;
}

.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.ilb {
  display: inline-block;
}
</style>
