<template>
  <div class="contain">
    <!-- <van-nav-bar title="名片" /> -->
    <div v-if="!loading" class="content">
      <div v-if="detail" class="b_content">
        <div class="card_wrap">
          <div class="b_card">
            <div class="c_logo"></div>
            <div class="c_name">{{ detail.username }}</div>
            <div class="c_bottom">
              <div class="cb_l">
                <div class="c_dept">{{ detail.deptF }}</div>
                <div class="c_posts">{{ detail.postsF }}</div>
              </div>
              <div class="cb_r">
                <div class="cb_mobile">
                  <span class="cbm_start">M</span> +86 - {{ detail.mobileF }}
                </div>
                <div class="cb_mobile cb_email">
                  <span v-show="detail.email" class="cbm_start">E-mail</span>{{ detail.email }}
                </div>
                <div class="cb_company">中建西南咨询顾问有限公司</div>
                <div class="cb_address">
                  中国 | 成都 | 兴隆湖 | 中建滨湖设计总部
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bc_bottom">
          <div class="bcb_l"></div>
          <div class="bcb_r">
            <div class="bcbr_title">兴咨云</div>
            <div class="bcbr_content">让天下没有难做的工程</div>
          </div>
        </div>
      </div>
      <van-empty v-else description="名片数据不存在或已删除" />
    </div>
    <div v-else>
      <van-loading color="#1989fa" size="24px" vertical>加载中...</van-loading>
    </div>
  </div>
</template>
<script>
import { baseURL } from "../config";
import request from "axios";
export default {
  data() {
    return {
      id: this.$route.query.id,
      loading: true,
      detail: "",
    };
  },
  methods: {
    getDetail() {
      if (!this.id) {
        this.loading = false;
        return;
      }
      request({
        url: `${baseURL}api/v1/user_details/${this.id}`,
      })
        .then((res) => {
          this.loading = false;
          console.log(res);
          if (res.status >= 200 && res.status < 300) {
            if (res.data.code == 0) {
              console.log(11);
              let data = res.data.data;
              if (data.depts.length > 0) {
                data.deptF = data.depts[0].title;
              } else {
                data.deptF = "";
              }
              if (data.posts.length > 0) {
                data.postsF = data.posts[0].title;
              } else {
                data.postsF = "";
              }
              if (data.mobile) {
                data.mobileF =
                  data.mobile.slice(0, 3) +
                  " " +
                  data.mobile.slice(3, 7) +
                  " " +
                  data.mobile.slice(7);
              } else {
                data.mobileF = "";
              }
              this.detail = data;
            }
          }
          // this.remainTime = 1662365312 * 1000 - now
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    toAppDetail() {
      let appUrl = `orgapp://pageActivityDetail?id=${this.id}`;
      let u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; // Android系统或者uc浏览器
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // iOS系统
      // 如果为Android系统
      if (isAndroid) {
        console.log("isAndroid", appUrl);
        window.location.href = appUrl; // 直接跳转到App的链接，可以是 scheme 也可以是 App link 链接
        window.setTimeout(function () {
          alert("跳转函数已执行");
          // window.location.href = appUrl;   // 3s后如果不能跳转到 App，则跳转到 App 的下载地址，一般是应用宝的对应的下载地址
        }, 3000);
        return;
      }
      // ios设备：原理：判断是否认识这个协议，认识则直接跳转，不认识就在这里下载appios();
      if (isiOS) {
        // console.log("isiOS")
        let startIndex = u.indexOf("iPhone OS") + 9;
        let endIndex = u.indexOf("like Mac OS") - 1;
        let num = +u.slice(startIndex, endIndex).split("_")[0]; // 计算版本号的前面数值
        if (num < 9) {
          window.location.href = appUrl; // URL scheme 链接
        } else {
          window.location.href = appUrl; // universal link 链接
        }
        window.setTimeout(function () {
          alert("跳转函数已执行");
          // window.location.href = appUrl;   // 3s后如果不能跳转到 App，则跳转到 AppStore 的下载地址
        }, 3000);
        return;
      }
    },
  },
  created() {
    document.title = "名片";
    this.getDetail();
  },
};
</script>
<style scoped lang="less">
.contain {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  .content {
    height: 100%;
    // background-color: #9affa5;
  }
  .b_content {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    height: 100%;
    background-color: #fff;
    .card_wrap {
      width: 100%;
      height: 100px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url(@/assets/card_wrap_bg.png);
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .bc_bottom {
      width: 100%;
      height: 82px;
      padding: 0 20px;
      flex: none;
      // background-color: #f00;
      border-top: 1px solid #e5e5e5;
      display: flex;
      align-items: center;
      .bcb_l {
        width: 70px;
        height: 70px;
        background-image: url(@/assets/bottom_logo.png);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .bcb_r {
        flex: 1;
        margin-left: 10px;
        .bcbr_title {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #1a1a1a;
          line-height: 16px;
        }
        .bcbr_content {
          height: 16px;
          margin-top: 4px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #9a9ca5;
          line-height: 16px;
        }
      }
    }
  }
  .b_card {
    width: 335px;
    height: 204px;
    // margin: 20vh auto;
    padding: 8px 18px 10px;
    box-shadow: 0px 2px 20px 1px #dde8f2;
    background-color: #fff;
    // background-image: url(@/assets/business_card_bg.png);
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    border-radius: 6px;
    .c_logo {
      width: 101px;
      height: 63px;
      background-image: url(@/assets/bc_logo.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .c_name {
      margin-top: 19px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 21px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .c_bottom {
      margin-top: 8px;
      display: flex;
      .cb_l {
        width: 90px;
        padding-right: 5px;
        font-size: 11px;
        font-family: PingFang SC-Light, PingFang SC;
        font-weight: 300;
        line-height: 21px;
        .c_dept {
          color: #9a9ca5;
        }
        .c_posts {
          margin-top: 3px;
          color: #9a9ca5;
        }
      }
      .cb_r {
        flex: 1;
        font-size: 11px;
        font-family: PingFang SC-Light, PingFang SC;
        font-weight: 300;
        line-height: 15px;
        color: #9a9ca5;
        .cb_mobile {
          .cbm_start {
            color: #0086d1;
          }
          &.cb_email{
            height: 15px;
          }
        }
        .cb_company {
          margin-top: 6px;
        }
      }
    }
  }

  .van-loading,
  .van-empty {
    margin-top: 40vh;
    transform: translateY(-50%);
  }
}
</style>
<style>
.colon {
  display: inline-block;
  margin: 0 4px;
  color: #868686;
}

.block {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  text-align: center;
  width: 21px;
  height: 21px;
  background: #0086d1;
  border-radius: 5px;
}
</style>