<template>
    <div class="contain">
        <div v-if="!loading" class="content">
            <div v-if="detail" class="b_content">
                <div class="card_box">
                    <img class="top_img" src="../assets/business_card_top.png" />
                    <div class="c_name">{{ detail.username }}</div>
                    <div class="c_dept">{{ detail.deptF }}</div>
                    <div v-if="detail.postsF" class="c_post">{{ detail.postsF }}</div>
                    <div class="c_address">{{ detail.addressF }}</div>

                </div>
                <img class="bottom_img" src="../assets/business_bottom.png" />
                <button class="btn_save" v-on:click="save">联系TA</button>
            </div>
            <van-empty v-else description="名片数据不存在或已删除" />

        </div>
        <div v-else>
            <van-loading color="#1989fa" vertical>加载中...</van-loading>
        </div>
    </div>
</template>
<script>
import { baseURL } from "../config";
import request from "axios";
export default {
    data() {
        return {
            id: this.$route.query.id,
            loading: true,
            detail: "",
        }
    },
    methods: {
        getDetail() {
            if (!this.id) {
                this.loading = false;
                return;
            }
            request({
                url: `${baseURL}api/v1/user_details/${this.id}`,
                // url: `http://172.15.36.207:8001/api/v1/user_details/${this.id}`,
            })
                .then((res) => {
                    this.loading = false;
                    console.log(res);
                    if (res.status >= 200 && res.status < 300) {
                        if (res.data.code == 0) {
                            let data = res.data.data;
                            if (data.card_post != "") {
                                data.deptF = data.card_post;
                            }
                            else if (data.depts.length > 0) {
                                data.deptF = data.depts[0].title;
                            } else {
                                data.deptF = "";
                            }
                            if (data.card_job != "") {
                                data.postsF = data.card_job;
                            }
                            else if (data.posts.length > 0) {
                                data.postsF = data.posts[0].title;
                            } else {
                                data.postsF = "";
                            }
                            var tempAddress = "地址：中国·成都·兴隆湖·中建滨湖设计总部\n邮编：610299";
                            if (data.tel) {
                                tempAddress += "\n电话：" + data.tel;
                            }
                            tempAddress += "\n手机：" + data.mobile;
                            if (data.email) {
                                tempAddress += "\n邮箱：" + data.email;
                            }
                            data.addressF = tempAddress;
                            this.detail = data;
                        }
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    console.log(err);
                });
        },
        save: function () {
            window.location.href = "tel:" + this.detail.mobile;
        },
    },
    created() {
        document.title = "名片";
        this.getDetail();
    },
};
</script>
<style scoped lang="less">
.contain {
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .content {
        height: 100%;
    }

    .b_content {
        display: block;
        flex-direction: column;
        height: 100%;
        background-color: #f8f8f8;
        overflow-y: scroll;
        // overflow-x: hidden;
        scroll-behavior: smooth;

        .card_box {
            margin: 16px 16px 0px 16px;
            padding: 25px 30px;
            background-color: white;
            height: auto;
            scroll-behavior: auto;

            .top_img {
                width: 100%;
                height: auto;
                scroll-behavior: auto;
            }

            .c_name {
                margin-top: 35px;
                font-size: 32px;
                font-family: fangsong;
                font-weight: 600;
                color: #1a1a1a;
                line-height: 46px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
            }

            .c_dept {
                margin-top: 16px;
                font-size: 14px;
                font-family: fangsong;
                color: #332C2B;
                font-weight: 400;
                line-height: 23px;
                white-space: pre-line;
                text-overflow: ellipsis;
                text-align: center;
            }

            .c_post {
                font-size: 14px;
                font-family: fangsong;
                color: #332C2B;
                font-weight: 400;
                line-height: 23px;
                white-space: pre-line;
                text-overflow: ellipsis;
                text-align: center;
            }

            .c_address {
                margin-top: 52px;
                padding-bottom: 40px;
                font-size: 14px;
                font-family: fangsong;
                color: #332C2B;
                font-weight: 400;
                line-height: 23px;
                white-space: pre-line;
                text-overflow: ellipsis;
                background-image: url(@/assets/business_card_mid.png);
                background-position: bottom;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        .bottom_img {
            width: 100%;
            height: auto;
            scroll-behavior: auto;
        }

        .btn_save {
            width: 80%;
            height: 46px;
            margin: 11px 10% 27px 10%;
            border-radius: 23px;
            color: white;
            font-size: 16px;
            background-image: linear-gradient(to right, #0086D1, #00A3FF);
            border: none;
        }
    }
}
</style>