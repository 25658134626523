import request from 'axios'
import { baseURL } from "../config";

export function getWeixin(data) {
    return request({
        baseURL,
        url:"/api/v1/wx/get_js_config",
        method:'post',
        data
    })
}
