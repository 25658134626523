// 微信网页IP，用于请求签名
// const weixinUrl = 'http://test.zjxnzx.cn:8018/' // 中建测试服域名
const weixinUrl = 'https://share.zjxnzx.cn/'

// 接口基础地址
// const baseURL = 'http://172.15.36.207:8001/' // 办公室本地
const baseURL = '//mido.zjxnzx.cn/'  
const life3URL = '//3life.zjxnzx.cn/'  
// const baseURL = 'http://118.24.42.250:9501/'  
// const life3URL = 'http://118.24.42.250:8015/'  

export { weixinUrl, baseURL,life3URL}